import { useEffect, useRef } from "react";
import styles from "./GameMessageForm.module.css";

export const GameMessageBox: React.FC<{ currentMessage: string }> = ({ currentMessage }) => {

  const messageBoxRef = useRef<HTMLDivElement>(null);

  const scrollToRight = () => {
    if (currentMessage === "" || messageBoxRef.current === null) {
      return
    }
    messageBoxRef.current?.scroll({
      left: messageBoxRef.current.scrollWidth,
      behavior: "instant",
    });
  };

  useEffect(() => {
    // Scroll to right as the message types out
    scrollToRight();
  }, [currentMessage]);

  useEffect(() => {
    // Scroll to right on resize
    window.addEventListener("resize", scrollToRight);
    return () => window.removeEventListener("resize", scrollToRight);
  }, []);

  return (
    <div ref={messageBoxRef} className={styles.messageBox}>
      <div id={`current-message ${(currentMessage === "") ? "inactive" : ""}`}>
        <div>{currentMessage || ""}</div>
      </div>
    </div>
  );

}
