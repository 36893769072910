/*
  This component was spun up by asking Github Copilot to sketch out
  an instagram-style comment section
  then modified
*/

import React, { useEffect, useRef } from 'react';
import styles from './CommentSection.module.css';

interface CommentSectionProps {
  comments: string[];
}

export const CommentSection: React.FC<CommentSectionProps> = ({
  comments,
}) => {

  const containerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    containerRef.current?.scroll({
      top: containerRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Scroll to bottom on new message
    scrollToBottom();
  }, [comments]);

  useEffect(() => {
    // Scroll to bottom on resize
    window.addEventListener("resize", scrollToBottom);
    return () => window.removeEventListener("resize", scrollToBottom);
  }, []);

  /**
   * Different divs to recreate the look (not functionality) of Instagram comments section
   */

  return (
    <div className={styles.commentSection}>
      <div className={styles.commentHeader}>
        <img src="assets/header.gif" alt="Conversation(A/I)symmetry" className={styles.animatedHeader} />
        <div className={styles.headerSpacer} />
        <div className={styles.topRight}>
          <img src="assets/insta_top_right.png" alt="Top Right" />
        </div>
      </div>
      <div ref={containerRef} className={styles.commentsList}>
        {comments.map((comment, index) => (
          <div className={styles.commentItem} key={index}>
            <div className={styles.profilePicture}>
              <img src="assets/insta_user.png" alt="Profile" />
            </div>
            <div className={styles.commentText}>{comment}</div>
          </div>
        ))}
      </div>
      <div className={styles.commentFooter}>
        <div className={styles.bottomLeft}>
          <img src="assets/insta_bottom_left.png" alt="Bottom Left" />
        </div>
        <div className={styles.bottomRight}>
          <img src="assets/insta_bottom_right.png" alt="Bottom Right" />
        </div>
      </div>
    </div>
  );
};

export default CommentSection;